<template>
    <div class="mobphone">
        <div class="mobphone-nei">
            <div class="mobphone-main">
                <div class="ph-title">
                    <p>用户登录</p>
                    <p class="xian"></p>
                </div>
                <div class="gongdan">
                    <div class="dan">
                        <div class="text"><span>* </span>账号: </div>
                        <div class="inputbox">
                            <el-input v-model="Phoneinput" placeholder="请输入账号" @keyup.enter.native="onSubmit"></el-input>
                        </div>
                    </div>
                    <div class="dan yzm">
                        <div class="text"><span>* </span>密码: </div>
                        <div class="inputbox">
                            <el-input type="password" v-model="Codeinput" placeholder="请输入密码" @keyup.enter.native="onSubmit"></el-input>
                        </div>
                    </div>
                    <div class="anniu duihao">
                        <el-button type="primary" @click="onSubmit" round @>登录</el-button>
                    </div>
                </div>
                <div class="enderQueren">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            delenteAnniu: false,
            Phoneinput: "", //账号
            Codeinput: "", //密码
        };
    },

    mounted() {
        if (localStorage.getItem('TZ-USER')) {
            this.$router.path("/")
        }
    },

    methods: {
        // 测试用
        // onceshi(){
        // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
        // 		console.log(123)
        // 		if(res.code == '200'){

        // 		}
        // 	})
        // },
        //提交绑定手机号
        onSubmit() {
            if (this.Phoneinput == "") {
                this.$message({ message: "请输入账号", type: "warning" });
                return;
            }
            if (this.Codeinput == "") {
                this.$message({ message: "请输入密码", type: "warning" });
                return;
            }
            var param = {
                admin_acc: this.Phoneinput,
                admin_pwd: this.Codeinput,
            };
            this.$service.get(this.$api.signIn, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data)
                    localStorage.setItem("TZ-USER", JSON.stringify(res.data));
                    localStorage.setItem("TZ-account", this.Phoneinput);  //账户名
                    localStorage.setItem("TZ-DATA", JSON.stringify(res.data)); //账户所有
                    window.location = window.location.origin + "/";
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.mobphone {
    width: 100%;
    height: 100vh;
    // text-align: center;
    background: url("https://2lc-oss.erlangcha.com/usergongju/phonebg.jpg")
        left no-repeat;
    position: relative;
    .mobphone-nei {
        position: absolute;
        left: calc(50% - 515px);
        top: calc(50% - 254px);
        width: 1029px;
        height: 527px;
        background: url("https://2lc-oss.erlangcha.com/usergongju/loginadmin.png")
            center no-repeat;
        transform: scale(0.85);
    }
    .mobphone-main {
        width: 490px;
        padding: 30px;
        margin-top: 30px;
        .ph-title {
            text-align: center;
            p {
                font-size: 26px;
                color: #006fc5;
                font-weight: 600;
            }
            .xian {
                width: 100px;
                height: 3px;
                border-radius: 3px;
                background: #006fc5;
                margin: 0 auto;
                margin-top: 10px;
            }
        }
        .gongdan {
            margin-top: 60px;
            padding: 0 20px;
            .dan {
                border-bottom: 1px solid #ddd;
                display: flex;
                line-height: 67px;

                .text {
                    min-width: auto;
                    font-size: 18px;
                    margin-right: 15px;
                    color: #666;
                    span {
                        color: red;
                    }
                }
                .inputbox {
                    width: 300px;
                }
                .el-input {
                    .el-input__inner {
                        border: none;
                    }
                }
            }
            .yzm {
                .inputbox {
                    display: flex;
                    .el-input {
                        margin-right: 10px;
                    }
                }
            }
            .anniu {
                width: 100%;
                margin-top: 50px;
                text-align: center;
                .el-button {
                    border-radius: 10px;
                    padding: 18px 50px;
                    font-size: 20px;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.mobphone {
    .el-input {
        .el-input__inner {
            height: 45px;
            line-height: 45px;
            font-size: 16px;
            border: none;
        }
    }
}
</style>
